import { http } from "@/plugins/http";

const actions = {
  async handleImgUpload({ commit }, imagetobeuploaded) {
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const formData = new FormData();
      formData.append("image", imagetobeuploaded);
      const resp = await http.post("/garment/upload-image", formData, options);
      if (resp.status >= 200 && resp.status < 400 && resp.data.imgUrl) {
        const obj = {
          message: "Image has been uploaded",
          color: "info",
        };
        commit("snackbar/showMessage", obj, { root: true });
        return resp.data;
      } else {
        const obj = {
          message: "Error uploading image",
          color: "error",
        };
        commit("snackbar/showMessage", obj, { root: true });
        return false;
      }
    } catch (error) {
      const obj = {
        message: "Error: Image exceeds the maximum file size of 7 megabytes",
        color: "error",
      };
      commit("snackbar/showMessage", obj, { root: true });
    }
  },
  async handleUserImageUpload({ commit }, imagetobeuploaded) {
    const queryString = window.location.search;

    // Create a URLSearchParams object
    const searchParams = new URLSearchParams(queryString);
    const token = searchParams.get("token"); // This will be "John"
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const formData = new FormData();
      formData.append("image", imagetobeuploaded);
      formData.append("token", token);
      const resp = await http.post(
        "/garment/upload-user-image",
        formData,
        options
      );
      if (resp.status >= 200 && resp.status < 400 && resp.data.imgUrl) {
        const obj = {
          message: "Image has been uploaded",
          color: "info",
        };
        commit("snackbar/showMessage", obj, { root: true });
        return resp.data;
      } else {
        const obj = {
          message: "Error uploading image",
          color: "error",
        };
        commit("snackbar/showMessage", obj, { root: true });
        return false;
      }
    } catch (error) {
      const obj = {
        message: "Error: Image exceeds the maximum file size of 7 megabytes",
        color: "error",
      };
      commit("snackbar/showMessage", obj, { root: true });
    }
  },
};

export default {
  namespaced: true,
  actions,
};
