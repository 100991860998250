import store from "../store/index";
import { getInstance } from "./index";
export const nullManufacturerGuard = (to, from, next) => {
  const authService = getInstance();
  const fn = () => {
    if (
      store.getters.portalId !== null ||
      authService.isManufAdmin ||
      authService.isManufRep
    ) {
      return next();
    } else {
      return next({ name: "manage-portals" });
    }
  };
  // if not authenticated, redirect to unauthorized
  if (!authService.isAuthenticated) {
    return next("/unauthorized");
  }
  // authed && access token retrieved
  else if (authService.accessTokenClaims) {
    return fn();
  }

  // Watch for the accessTokenClaims to be defined to check role
  authService.$watch("accessTokenClaims", (atc) => {
    if (atc !== null) {
      return fn();
    }
  });
};
