import Vue from "vue";
import Vuex from "vuex";
import customer from "./modules/customer.store";
import snackbar from "./modules/snackbar.store";
import measurement from "./modules/measurement.store";
import { http } from "@/plugins/http";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,

    storeId: parseInt(localStorage.getItem("storeIdLocal")) || null,
    store: null,

    portalId: parseInt(localStorage.getItem("portalIdLocal")) || null,
    portal: null,

    ongoingCustomer: null,
    updateTrigger: 0,
    curTab: "",

    parentGarInfo: null,
    notifications: [],
    pinnedOrders: [],
    savedFilters: [],
    columnSetting: [],
    appVersion: localStorage.getItem("appVersion") || "",
    isReloadRequired: localStorage.getItem("isReloadRequired") === "true",
    pinNavDrawer: false,
    defaultCamera: "",
    defaultCameraForMobile: "",
    isOpenMeasurement: false,
    isOpenModalInSettings: false,
    isApplying: false,
  },
  mutations: {
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setStoreId(state, storeId) {
      state.storeId = storeId;
    },
    setStore(state, store) {
      state.store = store;
      if (!localStorage.getItem("storeName")) {
        localStorage.setItem("storeName", store.name);
      }
    },
    setPortalId(state, portalId) {
      state.portalId = portalId;
    },
    setPortal(state, portal) {
      state.portal = portal;
    },
    setOngoingCustomer(state, customer) {
      state.ongoingCustomer = customer;
    },
    setParentGarInfo(state, info) {
      state.parentGarInfo = info;
    },
    clearNotifications(state) {
      state.notifications = [];
    },
    setNotifications(state, notifications) {
      state.notifications = notifications.map((el) => ({
        ...el,
        readHover: false,
        promptDelete: false,
      }));
    },
    addNotification(state, newNotification) {
      const notification = state.notifications.find(
        (el) => el.id == newNotification.id
      );
      if (notification) {
        notification.type = newNotification.type;
        notification.other = newNotification.other;
        notification.fromUserId = newNotification.fromUserId;
        notification.toUserId = newNotification.toUserId;
        notification.isRead = newNotification.isRead;
        notification.createdDate = newNotification.createdDate;
        notification.updatedDate = newNotification.updatedDate;
        notification.fromUserName = newNotification.fromUserName;
        notification.readHover = false;
        notification.promptDelete = false;
      } else {
        state.notifications.unshift({
          ...newNotification,
          readHover: false,
          promptDelete: false,
        });
      }
    },
    removeNotification(state, notificationId) {
      const index = state.notifications.findIndex(
        (el) => el.id == notificationId
      );
      if (index != -1) {
        state.notifications.splice(index, 1);
      }
    },
    setNotificationHover(state, { notificationId, hover }) {
      const notification = state.notifications.find(
        (el) => el.id == notificationId
      );
      if (notification) {
        notification.readHover = hover;
      }
    },
    setNotificationRead(state, { notificationId, isRead }) {
      const notification = state.notifications.find(
        (el) => el.id == notificationId
      );
      if (notification) {
        notification.isRead = isRead;
        notification.readHover = false;
      }
    },
    setPinnedOrders(state, pinnedOrders) {
      state.pinnedOrders = pinnedOrders;
    },
    setSavedFilters(state, savedFilters) {
      state.savedFilters = savedFilters;
    },
    setColumnSetting(state, columnSetting) {
      state.columnSetting = columnSetting;
    },
    setAutoUpdatePO(state, isAutoUpdateFabricPO) {
      state.store.isAutoUpdateFabricPO = isAutoUpdateFabricPO;
    },
    setAppVersion(state, appVersion) {
      if (appVersion !== state.appVersion && state.appVersion) {
        state.isReloadRequired = true;
        localStorage.setItem("isReloadRequired", "true");
      }
      state.appVersion = appVersion;
      localStorage.setItem("appVersion", appVersion);
    },
    setCurTab(state, curTab) {
      state.curTab = curTab;
      state.updateTrigger++;
    },
    setPinNavDrawer(state, pinNavDrawer) {
      state.pinNavDrawer = pinNavDrawer;
    },
    setDefaultCamera(state, defaultCamera) {
      state.defaultCamera = defaultCamera;
    },
    setDefaultCameraForMobile(state, defaultCameraForMobile) {
      state.defaultCamera = defaultCameraForMobile;
    },
    setOpenMeasurement(state, isOpenMeasurement) {
      state.isOpenMeasurement = isOpenMeasurement;
    },
    setOpenModalInSettings(state, isOpenModalInSettings) {
      state.isOpenModalInSettings = isOpenModalInSettings;
    },
    setIsApplying(state, isApplying) {
      state.isApplying = isApplying;
    },
    setMMForEnabledOptionForStore(
      state,
      isMasterMeasurementFormEnabledForStore
    ) {
      state.store.isMasterMeasurementFormEnabledForStore =
        isMasterMeasurementFormEnabledForStore;
    },
  },
  actions: {
    setLoading(context, isLoading) {
      context.commit("setLoading", isLoading);
    },
    setStoreId(context, storeId) {
      context.commit("setStoreId", storeId);
    },
    setStore(context, store) {
      context.commit("setStore", store);
    },
    setOngoingCustomer(context, customer) {
      context.commit("setOngoingCustomer", customer);
    },
    setParentGarInfo(context, item) {
      context.commit("setParentGarInfo", item);
    },
    setPortalId(context, portalId) {
      context.commit("setPortalId", portalId);
    },
    setPortal(context, portal) {
      context.commit("setPortal", portal);
    },
    addNotification(context, newNotification) {
      context.commit("addNotification", newNotification);
    },
    setNotifications(context, notifications) {
      context.commit("setNotifications", notifications);
    },
    setNotificationHover(context, data) {
      context.commit("setNotificationHover", data);
    },
    setNotificationRead(context, data) {
      context.commit("setNotificationRead", data);
    },
    removeNotification(context, notificationId) {
      context.commit("removeNotification", notificationId);
    },
    setAutoUpdatePO(context, isAutoUpdateFabricPO) {
      context.commit("setAutoUpdatePO", isAutoUpdateFabricPO);
    },
    setUpdateTrigger(context) {
      context.commit("setUpdateTrigger");
    },
    setCurTab(context, curTab) {
      context.commit("setCurTab", curTab);
    },
    setPinNavDrawer(context, pinNavDrawer) {
      context.commit("setPinNavDrawer", pinNavDrawer);
    },
    setDefaultCamera(context, defaultCamera) {
      context.commit("setDefaultCamera", defaultCamera);
    },
    setDefaultCameraForMobile(context, defaultCameraForMobile) {
      context.commit("setDefaultCameraForMobile", defaultCameraForMobile);
    },
    setOpenMeasurement(context, isOpenMeasurement) {
      context.commit("setOpenMeasurement", isOpenMeasurement);
    },
    setIsApplying(context, isApplying) {
      context.commit("setIsApplying", isApplying);
    },
    setMMForEnabledOptionForStore(
      context,
      isMasterMeasurementFormEnabledForStore
    ) {
      context.commit(
        "setMMForEnabledOptionForStore",
        isMasterMeasurementFormEnabledForStore
      );
    },
    async getPinnedOrders(context, userId) {
      const { commit } = context;
      try {
        const resp = await http.get(`/orders/pin-order/${userId}`);
        commit("setPinnedOrders", resp.data);
        return resp;
      } catch (error) {
        const obj = {
          message: "Favorites not found",
          color: "error",
        };
        commit("snackbar/showMessage", obj, { root: true });
        return error;
      }
    },
    async pinOrder(context, { userId, orderId, isPinned }) {
      const { commit } = context;
      try {
        const resp = await http.put(`/orders/pin-order/${orderId}`, {
          userId,
          isPinned,
        });

        const obj = {
          message: isPinned ? "Order pinned" : "Order unpinned",
          color: "info",
        };
        commit("snackbar/showMessage", obj, { root: true });
      } catch (error) {
        const obj = {
          message: `${error.response.statusText}`,
          color: "error",
        };
        commit("snackbar/showMessage", obj, { root: true });
        return error;
      }
    },
    async updateOrder(context, pinnedOrderIds) {
      const { commit } = context;
      try {
        await http.put(`/orders/pin-order/update-order`, {
          pinnedOrderIds,
        });
      } catch (error) {
        const obj = {
          message: `${error.response.statusText}`,
          color: "error",
        };
        commit("snackbar/showMessage", obj, { root: true });
        return error;
      }
    },
    async getSavedFilters(context, userId) {
      const { commit } = context;
      try {
        const resp = await http.get(`/garment/saved-filter/${userId}`);
        commit("setSavedFilters", resp.data);
        return resp;
      } catch (error) {
        const obj = {
          message: "Saved filters not found",
          color: "error",
        };
        commit("snackbar/showMessage", obj, { root: true });
        return error;
      }
    },
    async createSavedFilter(context, { userId, name, filter }) {
      const { commit } = context;
      try {
        const resp = await http.post(`/garment/saved-filter/${userId}`, {
          name,
          filter,
        });
        return resp;
      } catch (error) {
        const obj = {
          message: "Saved filters not found",
          color: "error",
        };
        commit("snackbar/showMessage", obj, { root: true });
        return error;
      }
    },
    async removeSavedFilter(context, filterId) {
      const { commit } = context;
      try {
        const resp = await http.delete(`/garment/saved-filter/${filterId}`);
        return resp;
      } catch (error) {
        const obj = {
          message: "Failed to remove saved filter",
          color: "error",
        };
        commit("snackbar/showMessage", obj, { root: true });
        return error;
      }
    },
    async updateSavedFilter(context, { filterId, name }) {
      const { commit } = context;
      try {
        const resp = await http.put(`/garment/saved-filter/${filterId}`, {
          name,
        });
        return resp;
      } catch (error) {
        const obj = {
          message: "Failed to update saved filter",
          color: "error",
        };
        commit("snackbar/showMessage", obj, { root: true });
        return error;
      }
    },
    async updateSavedFilterOrder(context, filterOrderIds) {
      const { commit } = context;
      try {
        await http.put(`/garment/saved-filter/update-order`, {
          filterOrderIds,
        });
      } catch (error) {
        const obj = {
          message: `${error.response.statusText}`,
          color: "error",
        };
        commit("snackbar/showMessage", obj, { root: true });
        return error;
      }
    },
    async getColumnSetting(context, userId) {
      const { commit } = context;
      try {
        const resp = await http.get(`/garment/column-setting/${userId}`);
        const data = resp.data.content || "";
        if (data) {
          const columnSetting = data.split(",").map((el) => +el);
          commit("setColumnSetting", columnSetting);
        }
        return resp;
      } catch (error) {
        const obj = {
          message: "Column Setting not found",
          color: "error",
        };
        commit("snackbar/showMessage", obj, { root: true });
        return error;
      }
    },
    async saveColumnSetting(context, { userId, colIds }) {
      const { commit } = context;
      try {
        await http.put(`/garment/column-setting/${userId}`, {
          colIds,
        });
        const obj = {
          message: "Column setting updated",
          color: "info",
        };
        commit("snackbar/showMessage", obj, { root: true });
      } catch (error) {
        const obj = {
          message: "Column Setting not found",
          color: "error",
        };
        commit("snackbar/showMessage", obj, { root: true });
        return error;
      }
    },
    async getLatestAppVersion(context) {
      const { commit } = context;
      try {
        const resp = await http.get(`/deploy-history/latest`);
        const deployHistory = resp.data;
        if (!deployHistory) {
          return;
        }
        commit("setAppVersion", deployHistory.name);
      } catch (error) {
        const obj = {
          message: "Column Setting not found",
          color: "error",
        };
        commit("snackbar/showMessage", obj, { root: true });
        return error;
      }
    },
    setOpenModalInSettings(context, isOpenModalInSettings) {
      context.commit("setOpenModalInSettings", isOpenModalInSettings);
    },
  },
  getters: {
    isLoading: (state) => state.loading,

    // store
    storeId: (state) => state.storeId,
    store: (state) => state.store,
    storeName: (state) => (state.store ? state.store.name : ""),
    storeDateFormat: (state) => state.store?.dateFormat || "MM/DD/YY",
    storeLogo: (state) => (state.store ? state.store.logo : ""),
    isPOEnabled: (state) => state.store && state.store.isPOEnabled,
    isGalleryEnabled: (state) => state.store && state.store.isGalleryEnabled,
    isLinkedEnabled: (state) => state.store && state.store.isLinkedEnabled,
    isGarmentShortcutEnabled: (state) => state.store?.isGarmentShortcutEnabled,
    isAltsEnabled: (state) => state.store && state.store.isAltsEnabled,
    isCalendarEnabled: (state) => state.store && state.store.isCalendarEnabled,
    isLogicField: (state) =>
      (state.store && state.store.isLogicField) || !state.store,
    isMfrLimited: (state) => state.store && state.store.isMfrLimited,
    isMultiStoreEnabled: (state) =>
      state.store && state.store.isMultiStoreEnabled,
    isReviewProcess: (state) => state.store && state.store.isReviewProcess,
    isUseReviewProcess: (state) =>
      state.store && state.store.isUseReviewProcess,
    isAutoUpdateFabricPO: (state) =>
      state.store &&
      state.store.isPOEnabled &&
      state.store.isAutoUpdateFabricPO,
    curStoreName: (state) => (state.store ? state.store.name : "Global"),
    portalName: (state) => (state.portal ? state.portal.name : "Global"),
    ongoingCustomer: (state) => state.ongoingCustomer,
    parentGarInfo: (state) => state.parentGarInfo,

    // manufacturer
    portalId: (state) => state.portalId,
    portal: (state) => state.portal,

    // notifications
    notifications: (state) => state.notifications,
    hasUnreadNotifications: (state) =>
      state.notifications.some((el) => !el.isRead),
    numberOfUnreadNotifications: (state) =>
      state.notifications.filter((el) => !el.isRead).length,

    pinnedOrders: (state) => state.pinnedOrders,
    savedFilters: (state) => state.savedFilters,
    columnSetting: (state) => state.columnSetting,
    isReloadRequired: (state) => state.isReloadRequired,
    updateTrigger: (state) => state.updateTrigger,
    pinNavDrawer: (state) => state.pinNavDrawer,
    isOpenMeasurement: (state) => state.isOpenMeasurement,
    isMeasurementBoundary: (state) =>
      state.store && state.store.isMeasurementBoundary,
    hasAllStatuses: (state) => state.store && state.store.hasAllStatuses,
    isMeasurementProfileEnabled: (state) =>
      state.store && state.store.isMeasurementProfileEnabled,
    isMasterMeasurementFormEnabled: (state) =>
      state.store && state.store.isMasterMeasurementFormEnabled,
    isMasterMeasurementFormEnabledForStore: (state) =>
      state.store && state.store.isMasterMeasurementFormEnabledForStore,
    mmUnit: (state) => state.store && state.store.mmUnit,

    isOpenModalInSettings: (state) => state.isOpenModalInSettings,
    defaultCamera: (state) => state.defaultCamera,
    defaultCameraForMobile: (state) => state.defaultCameraForMobile,
    isApplying: (state) => state.isApplying,
  },
  modules: {
    customer,
    snackbar,
    measurement,
  },
});
