import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import FabricIcon from "@/assets/FabricIcon.vue";
import FabricIconLight from "@/assets/FabricIcon_white.vue";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi", // default - only for display purposes
    values: {
      fabricIcon: {
        // name of our custom icon
        component: FabricIcon, // our custom component
      },
      fabricIconLight: {
        // name of our custom icon
        component: FabricIconLight, // our custom component
      },
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        background: "#f9f9f9",
        anchor: "#455e90", //link color #56688c
        accent: "#333333", //#44607c
        primary: "#7d7a70", //837f72 7f7d78
        secondary: "#2c2c2c", //292929 dark headers
        error: "#954345", //aa4345 red
        info: "#000000",
        success: "#458a77", //60a58b //3cae7f
        warning: "#ca8a0a", //arange
        section: "#517898",
        upgrade: "#416b6f",
        favorite: "#d6c072",
      },
      dark: {},
    },
  },
});
