// import Dashboard from "../views/store/Home.vue";
// import Customers from "../views/store/Customers.vue";
// import CustomerDetail from "../views/store/CustomerDetail.vue";
// import Orders from "../views/store/Orders.vue";
// import Garments from "../views/store/Garments.vue";
// import OrderDetail from "../views/store/OrderDetail.vue";
// import Garments from "../views/store/Garments.vue";
// import Forms from "../views/store/Forms.vue";
// import AdminSettings from "../views/store/AdminSettings.vue";
import { storeAdminGuard } from "../auth/storeAdminGuard.js";
import { authGuard } from "../auth/authGuard";
import { nullStoreGuard } from "../auth/nullStoreGuard";
import { multiguard } from "../auth/multiguard";

export const storeRoutes = [
  {
    path: "/pricing",
    name: "pricing",
    component: () => import("../views/store/Pricing.vue"),
    beforeEnter: multiguard([authGuard, storeAdminGuard, nullStoreGuard]),
  },
  {
    path: "/home",
    name: "home-store",
    component: () => import("../views/store/Home.vue"),
    beforeEnter: multiguard([authGuard, storeAdminGuard, nullStoreGuard]),
  },
  {
    path: "/customers",
    name: "Customers",
    component: () => import("../views/store/Customers.vue"),
    beforeEnter: multiguard([authGuard, storeAdminGuard, nullStoreGuard]),
  },
  {
    path: "/customers/:id",
    name: "customer-detail",
    component: () => import("../views/store/CustomerDetail.vue"),
    props: true,
    beforeEnter: multiguard([authGuard, storeAdminGuard, nullStoreGuard]),
  },
  {
    path: "/orders",
    name: "orders",
    component: () => import("../views/store/Orders.vue"),
    beforeEnter: multiguard([authGuard, storeAdminGuard, nullStoreGuard]),
  },
  {
    path: "/orders/:storeid/:aliasid",
    name: "orders-details",
    component: () => import("../views/store/OrderDetail.vue"),
    beforeEnter: multiguard([authGuard, storeAdminGuard, nullStoreGuard]),
  },
  {
    path: "/garments",
    name: "garments",
    component: () => import("../views/store/Garments.vue"),
    beforeEnter: multiguard([authGuard, storeAdminGuard, nullStoreGuard]),
  },
  {
    path: "/calendar",
    name: "calendar",
    component: () => import("../views/store/Calendar.vue"),
    beforeEnter: multiguard([authGuard, storeAdminGuard, nullStoreGuard]),
  },
  {
    path: "/shipments",
    name: "shipments",
    component: () => import("../views/store/Shipments.vue"),
    beforeEnter: multiguard([authGuard, storeAdminGuard, nullStoreGuard]),
  },
  {
    path: "/alterations",
    name: "alterations-global",
    component: () => import("../views/store/Alterations.vue"),
    beforeEnter: multiguard([authGuard, storeAdminGuard, nullStoreGuard]),
  },
  {
    path: "/fabric-orders",
    name: "fabric-orders",
    component: () => import("../views/store/FabricOrders.vue"),
    beforeEnter: multiguard([authGuard, storeAdminGuard, nullStoreGuard]),
  },
  {
    path: "/settings",
    name: "admin-settings",
    component: () => import("../views/store/AdminSettings.vue"),
    beforeEnter: multiguard([authGuard, storeAdminGuard, nullStoreGuard]),
    alias: ["/settings/edit", "/settings/edit/field"],
  },
  {
    path: "/account",
    name: "account-settings",
    component: () => import("../views/store/AccountSettings.vue"),
    beforeEnter: multiguard([authGuard, storeAdminGuard, nullStoreGuard]),
  },
  {
    path: "/help",
    component: () => import("@/views/store/HelpLanding.vue"),
    beforeEnter: multiguard([authGuard, storeAdminGuard, nullStoreGuard]),
  },
  {
    path: "/help/:postId",
    name: "HelpArticle",
    component: () => import("@/views/store/Help.vue"),
    beforeEnter: multiguard([authGuard, storeAdminGuard, nullStoreGuard]),
  },
  {
    path: "/tutorial-sales",
    name: "TutorialSales",
    component: () => import("@/views/store/HelpTutorialSales.vue"),
    beforeEnter: multiguard([authGuard, storeAdminGuard, nullStoreGuard]),
    props: true,
  },
  {
    path: "/:path?",
    name: "/",
    component: () => import("../views/store/Home.vue"),
    beforeEnter: multiguard([authGuard, storeAdminGuard, nullStoreGuard]),
  },
];
