<template>
  <span v-if="date">
    <template v-if="storeDateFormat === DATE_FORMATS.European">
      {{ day }}<span class="date-sep">/</span>{{ month
      }}<span class="date-sep">/</span>{{ year }}
    </template>
    <template v-else-if="storeDateFormat === DATE_FORMATS.ISO">
      {{ year }}<span class="date-sep">/</span>{{ month
      }}<span class="date-sep">/</span>{{ day }}
    </template>
    <template v-else>
      {{ month }}<span class="date-sep">/</span>{{ day
      }}<span class="date-sep">/</span>{{ year }}
    </template>
    <span class="ml-1" v-if="showTime">
      {{ hour }}<span class="date-sep">:</span>{{ minute }} {{ ampm }}
    </span>
  </span>
  <span v-else></span>
</template>

<script>
import { mapGetters } from "vuex";
import { DATE_FORMATS } from "@/utils/constant";

export default {
  name: "DateSpan",
  props: {
    date: {
      type: [String, Date],
      default: "",
    },
    fullYear: {
      type: Boolean,
      default: false,
    },
    showTime: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["storeDateFormat"]),
    DATE_FORMATS() {
      return DATE_FORMATS;
    },
    year() {
      const date = new Date(this.date);
      const dateStr = this.fullYear
        ? date.getFullYear()
        : date.getFullYear().toString().slice(-2).padStart(2, "0");
      return dateStr;
    },
    month() {
      const date = new Date(this.date);
      return String(date.getMonth() + 1).padStart(2, "0");
    },
    day() {
      const date = new Date(this.date);
      return String(date.getDate()).padStart(2, "0");
    },
    hour() {
      const date = new Date(this.date);
      return String(date.getHours()).padStart(2, "0");
    },
    minute() {
      const date = new Date(this.date);
      return String(date.getMinutes()).padStart(2, "0");
    },
    ampm() {
      const date = new Date(this.date);
      return date.getHours() >= 12 ? "PM" : "AM";
    },
  },
};
</script>
