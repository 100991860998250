import { http } from "@/plugins/http";

const state = {
  customer: {},
  garmentTypes: [],
  isAddModal: false,
};

const getters = {
  getCustomer(state) {
    return state.customer;
  },
  getGarmentTypes(state) {
    return state.garmentTypes;
  },
  isAddModal(state) {
    return state.isAddModal;
  },
};

const actions = {
  async fetchCustomer({ rootState, dispatch, commit }, payload) {
    const { customerId } = payload;
    dispatch("setLoading", true, { root: true });
    try {
      const resp = await http.get(
        `/customers/api/${customerId}/${rootState.storeId}`
      );
      commit("SET_CUSTOMER", resp.data);
      dispatch("setOngoingCustomer", resp.data, { root: true });
      dispatch("setLoading", false, { root: true });
      return resp;
    } catch (error) {
      const obj = {
        message: "Customer not found",
        color: "error",
      };
      commit("snackbar/showMessage", obj, { root: true });
      return error;
    }
  },
  async fetchCustomerWithNoLoading({ rootState, dispatch, commit }, payload) {
    const { customerId } = payload;
    try {
      const resp = await http.get(
        `/customers/api/${customerId}/${rootState.storeId}`
      );
      commit("SET_CUSTOMER", resp.data);
      dispatch("setOngoingCustomer", resp.data, { root: true });
      return resp;
    } catch (error) {
      const obj = {
        message: "Customer not found",
        color: "error",
      };
      commit("snackbar/showMessage", obj, { root: true });
      return error;
    }
  },
  async fetchCustomerWithNoLoadingOnPortal({ dispatch, commit }, payload) {
    const { customerId } = payload;
    try {
      const resp = await http.get(`/m/portals/customers/${customerId}`);
      commit("SET_CUSTOMER", resp.data);
      dispatch("setOngoingCustomer", resp.data, { root: true });
      return resp;
    } catch (error) {
      const obj = {
        message: "Customer not found",
        color: "error",
      };
      commit("snackbar/showMessage", obj, { root: true });
      return error;
    }
  },
  async fetchGarmentType({ rootState, dispatch, commit }) {
    try {
      const resp = await http.get(`/forms?storeId=${rootState.storeId}`);
      commit("SET_GARMENT_TYPES", resp.data);
      return resp;
    } catch (error) {
      const obj = {
        message: `${error.response.statusText}`,
        color: "error",
      };
      commit("snackbar/showMessage", obj, { root: true });
      return error;
    }
  },
  setAddModal({ rootState, dispatch, commit }, data) {
    commit("SET_ADD_MODAL", data);
  },
};

const mutations = {
  SET_CUSTOMER(state, data) {
    state.customer = data;
  },
  SET_GARMENT_TYPES(state, data) {
    state.garmentTypes = data;
  },
  SET_ADD_MODAL(state, data) {
    state.isAddModal = data;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
