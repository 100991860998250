function isUndefined(value) {
  return value === undefined;
}

function evaluateGuards(guards, to, from, next) {
  const guardsLeft = guards.slice(0);
  const nextGuard = guardsLeft.shift();

  if (isUndefined(nextGuard)) {
    next();
    return;
  }

  nextGuard(to, from, (nextArg) => {
    if (isUndefined(nextArg)) {
      evaluateGuards(guardsLeft, to, from, next);
      return;
    }

    next(nextArg);
  });
}

export const multiguard = function (guards) {
  return (to, from, next) => {
    return evaluateGuards(guards, to, from, next);
  };
};
